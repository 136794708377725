<template>
  <v-container
    id="login"
    fluid
    tag="section"
    class="pa-16"
  >
    <v-row justify="center">
      <v-col
        cols="12"
        md="4"
      >
        <base-material-card
          color="purple darken-3"
        >
          <template v-slot:heading>
            <div class="text-h3 font-weight-light">
              Entrar
            </div>
          </template>

          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="12"
              >
                <v-text-field
                  ref="username"
                  v-model="credentials.username"
                  label="Usuário"
                  autofocus
                  @keyup.enter="$refs.password.focus()"
                />
              </v-col>

              <v-col
                cols="12"
                sm="12"
                class="mt-n8"
              >
                <v-text-field
                  ref="password"
                  v-model="credentials.password"
                  type="password"
                  label="Senha"
                  @keyup.enter="login"
                />
              </v-col>
            </v-row>

            <v-row>
              <span
                v-if="error"
                class="red--text pt-2 pl-4"
              >Usuário ou senha incorretos</span>
              <v-spacer />
              <v-btn
                color="success darken-1"
                class="mb-4"
                @click="login"
              >
                Entrar
              </v-btn>
            </v-row>
          </v-container>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import axios from 'axios'
  import qs from 'qs'

  export default {
    name: 'Login',

    data () {
      return {
        credentials: {
          username: null,
          password: null,
        },
        error: false,
      }
    },

    methods: {
      login () {
        this.error = false
        const loginUrl = 'https://us-central1-covid-pirassununga.cloudfunctions.net/login'
        axios.post(loginUrl, qs.stringify(this.credentials))
          .then((response) => {
            this.error = false
            this.$store.dispatch('authenticate')
            this.$router.replace('admin')
          })
          .catch(() => {
            this.error = true
          })
      },
    },
  }
</script>
